<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">PAYROLL</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-3">
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            v-model="type_of_employee"
            class="mx-2"
            dense
            outlined
            label="Selection"
            :items="['Active','Temporary Deactivate','Permanent Deactivate']"
            @change="selected_month"
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            v-model="month_of_id"
            class="mx-2"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_month"
            outlined
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            outlined
            v-model="duration"
            class="mx-2"
            :items="['1-15','16-30/31']"
            label="Duration"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_month"
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            outlined
            class="mx-2"
            v-model="category_id"
            dense
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            :rules="rules.combobox_rule"
            @change="selected_category"
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            outlined
            v-model="batch_no"
            class="mx-2"
            :items="batch_no_items"
            item-value="batch_no"
            item-text="batch_no"
            label="Batch"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_data"
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            outlined
            v-model="positionss"
            class="mx-2"
            :items="position_items"
            item-value="position"
            item-text="position"
            label="Position"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_data"
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            outlined
            v-model="bank_type"
            class="mx-2"
            :items="bank_type_items"
            item-value="bank_type"
            item-text="bank_type"
            label="Bank Type"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_data"
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            outlined
            v-model="sorted_by"
            class="mx-2"
            :items="['Name','Position','Branch']"
            label="Sort By"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_data"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="headers"
                    :items="payroll_data"
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title v-if="!printingss && payroll_data.length > 0">Print</v-toolbar-title>
            <v-icon
              v-if="!printingss && payroll_data.length > 0"
              color="success"
              @click="print_data()"
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            <v-progress-circular
              color="info"
              indeterminate
              v-if="printingss"
            ></v-progress-circular>
            <v-toolbar-title v-if="!printingss && payroll_data.length > 0"> &nbsp; Print Bank
              Generation
              Format
            </v-toolbar-title>
            <v-icon
              v-if="!printingss && payroll_data.length > 0"
              color="success"
              @click="print_data3()"
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            <v-progress-circular
              color="info"
              indeterminate
              v-if="printingss"
            ></v-progress-circular>
            <v-divider class="mx-4" inset vertical
                       v-if="!printingss && payroll_data.length > 0"></v-divider>
            <v-toolbar-title v-if="!printingss && payroll_data.length > 0">
              <v-select
                outlined
                v-model="deductions_type"
                class="mx-2 mt-7"
                :items="['SSS Deductions','SSS Loan Deductions','PhilHealth Deductions','Pag-ibig Deductions','Pag-ibig Loan Deductions','Coop Deductions','Cash Bond Deductions','Tax Deductions','Auto Loan Deductions','Cash Advance Deductions','Accounting Loan Deductions','Others Deductions','Other Receivables Deductions','Motor Finance Deductions','Plan Deductions','Cash Bond Deductions']"
                label="Print Deductions"
                required
                :rules="rules.combobox_rule"
                dense
                @change="selected_data"
              ></v-select>
            </v-toolbar-title>
            <v-icon
              v-if="!printingss && payroll_data.length > 0 && deductions_type!=''"
              color="info"
              @click="print_data2()"
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            <v-progress-circular
              color="info"
              indeterminate
              v-if="printingss"
            ></v-progress-circular>
            <!--            <v-divider class="mx-4" inset vertical-->
            <!--                       v-if="!printingss && payroll_data.length > 0"></v-divider>-->
            <!--            &lt;!&ndash;            &ndash;&gt;-->
            <!--            <v-toolbar-title v-if="!printingss && payroll_data.length > 0">Print Tax Deductions-->
            <!--            </v-toolbar-title>-->
            <!--            <v-icon-->
            <!--              v-if="!printingss && payroll_data.length > 0"-->
            <!--              color="info"-->
            <!--              @click="print_data3()"-->
            <!--            >-->
            <!--              {{ icons.mdiPrinter }}-->
            <!--            </v-icon>-->
            <!--            <v-progress-circular-->
            <!--              color="info"-->
            <!--              indeterminate-->
            <!--              v-if="printingss"-->
            <!--            ></v-progress-circular>-->
            <!--            <v-divider class="mx-4" inset vertical-->
            <!--                       v-if="!printingss && payroll_data.length > 0"></v-divider>-->

          </v-toolbar>
          <v-toolbar flat>
            <v-toolbar-title>Payroll Data</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearch"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ (payroll_data
              .map(function (x) {
              return x.id
              })
              .indexOf(item.id)+1) }}
            </td>
            <td>
              {{ item.branch }}
            </td>
            <td>
              {{ item.employee.last_name+','+item.employee.first_name+' '+item.employee.middle_name
              }}
            </td>
            <td>
              {{ item.position }}
            </td>
            <td>
              {{ item.status }}
            </td>
            <td>
              {{ formatPrice(item.compensation_rate) }}
            </td>
            <td>
              {{ item.no_of_days }}
            </td>
            <td>
              {{ formatPrice(item.holiday_pay) }}
            </td>
            <td>
              {{ formatPrice(parseFloat(item.bod_allowance)+parseFloat(item.rice_allowance)
              +parseFloat(item.trainee_allowances)+parseFloat(item.birthday_allowances)
              +parseFloat(item.transpo_allowances)+parseFloat(item.accomodation_allowances)+parseFloat(item.incentives_allowances))
              }}
            </td>
            <td>
              {{ formatPrice(item.gross_salary) }}
            </td>
            <td>
              {{ formatPrice(item.absent_amount) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_late) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_sss) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_sss_loan) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_philhealth) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_pagibig) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_pagibig_loan) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_auto_loan) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_motor) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_coop_loan) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_loan) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_ca) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_plan) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_others) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_orec) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_coop_save) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_tax) }}
            </td>
            <td>
              {{ formatPrice(item.deduction_cash_bond) }}
            </td>
            <td>
              {{ formatPrice(item.net_salary) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiAccountSearch,
    mdiPrinter,
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      month_of_id: '',
      type_of_employee: '',
      deductions_type: '',
      month_of_items: [],
      payroll_data: [],
      duration: '',
      sorted_by: 'Name',
      bank_type: 'All',
      bank_type_items: [],
      positionss: 'All',
      position_items: [],
      batch_no: 1,
      batch_no_items: [],
      category_id: '',
      category_items: [],
      search: '',
      headers: [
        {text: 'No.', value: 'id', sortable: false},
        {text: 'Branch', value: 'date_of_deposit', sortable: false},
        {text: 'Name', value: 'employee.last_name', sortable: false},
        {text: 'Position', value: 'amount', sortable: false},
        {text: 'Status', value: 'bank_code_name', sortable: false},
        {text: 'Rate', value: 'bank_code_name', sortable: false},
        {text: 'Days', value: 'bank_code_name', sortable: false},
        {text: 'Holiday Pay', value: 'bank_code_name', sortable: false},
        {text: 'Allowances', value: 'bank_code_name', sortable: false},
        {text: 'Gross Salary', value: 'bank_code_name', sortable: false},
        {text: 'Absent', value: 'bank_code_name', sortable: false},
        {text: 'Late', value: 'bank_code_name', sortable: false},
        {text: 'SSS', value: 'bank_code_name', sortable: false},
        {text: 'SSS Loan', value: 'bank_code_name', sortable: false},
        {text: 'PhilHealth', value: 'bank_code_name', sortable: false},
        {text: 'Pag-Ibig', value: 'bank_code_name', sortable: false},
        {text: 'Pag-Ibig Loan', value: 'bank_code_name', sortable: false},
        {text: 'Auto Loan', value: 'bank_code_name', sortable: false},
        {text: 'Motor Finance', value: 'bank_code_name', sortable: false},
        {text: 'Coop Loan', value: 'bank_code_name', sortable: false},
        {text: 'Acct Loan', value: 'bank_code_name', sortable: false},
        {text: 'CA', value: 'bank_code_name', sortable: false},
        {text: 'Plan', value: 'bank_code_name', sortable: false},
        {text: 'Others', value: 'bank_code_name', sortable: false},
        {text: 'OREC', value: 'bank_code_name', sortable: false},
        {text: 'Coop Save', value: 'bank_code_name', sortable: false},
        {text: 'Tax', value: 'bank_code_name', sortable: false},
        {text: 'Cash Bond', value: 'bank_code_name', sortable: false},
        {text: 'Net', value: 'bank_code_name', sortable: false},
      ],
      printingss: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiAccountSearch,
          mdiPrinter,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['name', 'employee_id', 'position', 'is_head']),
      ...mapGetters('authentication', [
        'employee_id',
        'address',
        'contact',
        'name',
        'department',
        'position',
        'employee_category_id',
        'employee_branch_data',
        'employee_branch_id',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
        'damayan',
        'chapel',
        'factory',
        'coop',
        'printing',
        'accounting',
        'admin',
        'amd',
        'audit',
        'book',
        'creative',
        'ecut',
        'hr',
        'it',
        'prot',
        'trea',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee', 'initialize_payroll_selected_category', 'initialize_payroll', 'payroll']),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.month_of_items = response.data[0].month_of
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        const data = new FormData()
        data.append('type_of_employee', this.type_of_employee);
        data.append('month_of_id', this.month_of_id);
        data.append('duration', this.duration);
        this.initialize_payroll(data)
          .then(response => {
            this.category_items = response.data[0].category
            this.batch_no_items = response.data[0].batch_no
            this.bank_type_items = response.data[0].bank_type
            this.bank_type_items.splice(0, 0, {
              bank_type: 'All'
            })
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_category() {
        const data = new FormData()
        data.append('month_of_id', this.month_of_id);
        data.append('duration', this.duration);
        data.append('category_id', this.category_id);
        this.initialize_payroll_selected_category(data)
          .then(response => {
            this.position_items = response.data
            this.position_items.splice(0, 0, {
              position: 'All'
            })
            this.selected_data()
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_data() {
        const data = new FormData()
        data.append('month_of_id', this.month_of_id);
        data.append('duration', this.duration);
        data.append('category_id', this.category_id);
        data.append('batch_no', this.batch_no);
        data.append('position', this.positionss);
        data.append('sorted_by', this.sorted_by);
        data.append('bank_type', this.bank_type);
        this.payroll(data)
          .then(response => {
            this.payroll_data = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async print_data() {
        this.printingss = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printingss_ = this.printing

        widths = [10, 40, 95, 50, 20, 20, 20, 20, 20, 20, 20, 20, 20,20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,20,20,20, 30]
        payments_array.push([
          {text: 'No.', alignment: 'left', style: 'main_info'},
          {text: 'Branch', alignment: 'left', style: 'main_info'},
          {text: 'Name', alignment: 'left', style: 'main_info'},
          {text: 'Position', alignment: 'left', style: 'main_info'},
          {text: 'Status', alignment: 'left', style: 'main_info'},
          {text: 'Rate', alignment: 'left', style: 'main_info'},
          {text: 'Days', alignment: 'left', style: 'main_info'},
          {text: 'Holiday Pay', alignment: 'left', style: 'main_info'},
          {text: 'Allowances', alignment: 'left', style: 'main_info'},
          {text: 'Gross Salary', alignment: 'left', style: 'main_info'},
          {text: 'Absent', alignment: 'left', style: 'main_info'},
          {text: 'Late', alignment: 'left', style: 'main_info'},
          {text: 'SSS', alignment: 'left', style: 'main_info'},
          {text: 'SSS Loan', alignment: 'left', style: 'main_info'},
          {text: 'PhilHealth', alignment: 'left', style: 'main_info'},
          {text: 'Pag-Ibig', alignment: 'left', style: 'main_info'},
          {text: 'Pag-Ibig Loan', alignment: 'left', style: 'main_info'},
          {text: 'Auto Loan', alignment: 'left', style: 'main_info'},
          {text: 'Motor Finance', alignment: 'left', style: 'main_info'},
          {text: 'Coop Loan', alignment: 'left', style: 'main_info'},
          {text: 'Acct Loan', alignment: 'left', style: 'main_info'},
          {text: 'CA', alignment: 'left', style: 'main_info'},
          {text: 'Plan', alignment: 'left', style: 'main_info'},
          {text: 'Others', alignment: 'left', style: 'main_info'},
          {text: 'OREC', alignment: 'left', style: 'main_info'},
          {text: 'Coop Save', alignment: 'left', style: 'main_info'},
          {text: 'Tax', alignment: 'left', style: 'main_info'},
          {text: 'Cash Bond', alignment: 'left', style: 'main_info'},
          {text: 'Net', alignment: 'left', style: 'main_info'},
        ])

        var holiday_pay = 0
        var allowances = 0
        var gross_salary = 0
        var absent = 0
        var late = 0
        var sss = 0
        var sss_loan = 0
        var phil_health = 0
        var pag_ibig = 0
        var pag_ibig_loan = 0
        var auto_loan = 0
        var motor_finance = 0
        var coop_loan = 0
        var acct_loan = 0
        var cash_advance = 0
        var plan = 0
        var others = 0
        var orec = 0
        var coop_save = 0
        var tax = 0
        var cash_bond = 0
        var net = 0
        this.payroll_data.forEach(function (item, index, payment) {
          allowances += parseFloat(item.bod_allowance) + parseFloat(item.rice_allowance)
            + parseFloat(item.trainee_allowances) + parseFloat(item.birthday_allowances)
            + parseFloat(item.transpo_allowances) + parseFloat(item.accomodation_allowances) + parseFloat(item.incentives_allowances)
          holiday_pay += parseFloat(item.holiday_pay)
          absent += parseFloat(item.absent_amount)
          gross_salary += parseFloat(item.gross_salary)
          late += parseFloat(item.deduction_late)
          sss += parseFloat(item.deduction_sss)
          sss_loan += parseFloat(item.deduction_sss_loan)
          phil_health += parseFloat(item.deduction_philhealth)
          pag_ibig += parseFloat(item.deduction_pagibig)
          pag_ibig_loan += parseFloat(item.deduction_pagibig_loan)
          auto_loan += parseFloat(item.deduction_auto_loan)
          motor_finance += parseFloat(item.deduction_motor)
          coop_loan += parseFloat(item.deduction_coop_loan)
          acct_loan += parseFloat(item.deduction_loan)
          cash_advance += parseFloat(item.deduction_ca)
          plan += parseFloat(item.deduction_plan)
          others += parseFloat(item.deduction_others)
          orec += parseFloat(item.deduction_orec)
          coop_save += parseFloat(item.deduction_coop_save)
          tax += parseFloat(item.deduction_tax)
          cash_bond += parseFloat(item.deduction_cash_bond)
          net += parseFloat(item.net_salary)
          payments_array.push([
            {
              text: index + 1,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.branch,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.position,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.status,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.compensation_rate / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.no_of_days,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.holiday_pay / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (parseFloat(item.bod_allowance) + parseFloat(item.rice_allowance)
                + parseFloat(item.trainee_allowances) + parseFloat(item.birthday_allowances)
                + parseFloat(item.transpo_allowances) + parseFloat(item.accomodation_allowances) + parseFloat(item.incentives_allowances) / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.gross_salary / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.absent_amount / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_late / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_sss / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_sss_loan / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_philhealth / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_pagibig / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_pagibig_loan / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_auto_loan / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_motor / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_coop_loan / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_loan / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_ca / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_plan / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_others / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_orec / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_coop_save / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_tax / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.deduction_cash_bond / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: (item.net_salary / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: '',
              }, {
                text: '',
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: '',
              },
              {
                text: (holiday_pay / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (allowances / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (gross_salary / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (absent / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (late / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (sss / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (sss_loan / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (phil_health / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (pag_ibig / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (pag_ibig_loan / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (auto_loan / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (motor_finance / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (coop_loan / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (acct_loan / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (cash_advance / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (plan / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (others / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (orec / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (coop_save / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (tax / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (cash_bond / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: (net / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[
                      this.month_of_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.month_of_id)
                      ].month_of,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'DURATION: ',
                  {
                    text: this.duration,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'CATEGORY: ',
                  {
                    text: this.category_items[
                      this.category_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.category_id)
                      ].category,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'BATCH #: ',
                  {
                    text: this.batch_no,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'POSITION: ',
                  {
                    text: this.position_items[
                      this.position_items
                        .map(function (x) {
                          return x.position
                        })
                        .indexOf(this.positionss)
                      ].position,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'BANK TYPE: ',
                  {
                    text: this.bank_type_items[
                      this.bank_type_items
                        .map(function (x) {
                          return x.bank_type
                        })
                        .indexOf(this.bank_type)
                      ].bank_type,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LEGAL',
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image:
                    this.position === 'ADMIN'
                      ? this.admin
                      : this.position === 'TREASURY'
                      ? this.trea
                      : this.position === 'ACCOUNTING'
                        ? this.accounting
                        : this.position === 'AMD'
                          ? this.amd
                          : this.position === 'PROPERTY CUSTODIAN'
                            ? this.prot
                            : this.position === 'HUMAN RESOURCE'
                              ? this.hr
                              : this.position === 'BOOKKEEPING'
                                ? this.book
                                : this.position === 'CMD'
                                  ? this.accounting
                                  : imgData,
                  width: 60,
                  height: 54,
                  style: 'logo',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'PAYROLL REPORT',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printingss_, width: 34, height: 20, style: 'logo'},
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printingss = false
      },
      async print_data2() {
        this.printingss = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printingss_ = this.printing

        switch (this.deductions_type) {
          case "Coop Deductions":
            widths = [20, 80, 120, 130, 40, 40, 40,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'Coop Loan', alignment: 'left', style: 'main_info'},
              {text: 'Coop Save', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "Tax Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'Tax', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "Accounting Loan Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'Accounting Loan', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "SSS Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'SSS', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "SSS Loan Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'SSS Loan', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "PhilHealth Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'PhilHealth', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "Pag-ibig Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'Pag-Ibig', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "Pag-ibig Loan Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'Pag-Ibig Loan', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "Auto Loan Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'Auto Loans', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "Cash Advance Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'Cash Advance', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "Others Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'Others', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "Other Receivables Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'ORECs', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "Motor Finance Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'Motor Finance', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "Plan Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'Plan', alignment: 'left', style: 'main_info'},
            ])
            break;
          case "Cash Bond Deductions":
            widths = [20, 80, 120, 130, 40, 60,]
            payments_array.push([
              {text: 'No.', alignment: 'left', style: 'main_info'},
              {text: 'Branch', alignment: 'left', style: 'main_info'},
              {text: 'Name', alignment: 'left', style: 'main_info'},
              {text: 'Position', alignment: 'left', style: 'main_info'},
              {text: 'Status', alignment: 'left', style: 'main_info'},
              {text: 'Cash Bond', alignment: 'left', style: 'main_info'},
            ])
            break;
        }
        var coop_loan = 0
        var coop_save = 0
        var total = 0
        var dec_ty = this.deductions_type
        this.payroll_data.forEach(function (item, index, payment) {
          switch (dec_ty) {
            case "Coop Deductions":
              coop_loan += parseFloat(item.deduction_coop_loan)
              coop_save += parseFloat(item.deduction_coop_save)
              if ((parseFloat(item.deduction_coop_loan) + parseFloat(item.deduction_coop_save)) > 0) {
                payments_array.push([
                  {
                    text: index + 1,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.branch,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.position,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.status,
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                  {
                    text: (item.deduction_coop_loan / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: (item.deduction_coop_save / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                ])
              }

              if (Object.is(payment.length - 1, index)) {
                // execute last item logic
                payments_array.push([
                  {
                    text: '',
                  }, {
                    text: '',
                  },
                  {
                    text: 'TOTAL',
                    alignment: 'right',
                    style: 'tableExample2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },

                  {
                    text: (coop_loan / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                  {
                    text: (coop_save / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                ])
              }
              break;
            case "Tax Deductions":
            case "SSS Deductions":
            case "PhilHealth Deductions":
            case "Pag-ibig Deductions":
            case "Accounting Loan Deductions":
            case "SSS Loan Deductions":
            case "Pag-ibig Loan Deductions":
            case "Other Receivables Deductions":
            case "Cash Bond Deductions":
              total +=(dec_ty === 'Tax Deductions' ? parseFloat(item.deduction_tax) :
                (dec_ty === 'SSS Deductions' ? parseFloat(item.deduction_sss) :
                  (dec_ty === 'PhilHealth Deductions' ? parseFloat(item.deduction_philhealth) :
                    (dec_ty === 'Pag-ibig Deductions' ? parseFloat(item.deduction_pagibig) :
                      (dec_ty === 'Accounting Loan Deductions' ? parseFloat(item.deduction_loan) :
                        (dec_ty === 'SSS Loan Deductions' ? parseFloat(item.deduction_sss_loan) :
                          (dec_ty === 'Pag-ibig Loan Deductions' ? parseFloat(item.deduction_pagibig_loan) :
                            (dec_ty === 'Other Receivables Deductions' ? parseFloat(item.deduction_orec) :
                              (dec_ty === 'Cash Bond Deductions' ? parseFloat(item.deduction_cash_bond) : 0)))))))))
              if (
                dec_ty === 'Tax Deductions' ? (parseFloat(item.deduction_tax)) > 0 :
                  (dec_ty === 'SSS Deductions' ? (parseFloat(item.deduction_sss)) > 0 :
                    (dec_ty === 'PhilHealth Deductions' ? (parseFloat(item.deduction_philhealth)) > 0 :
                      (dec_ty === 'Pag-ibig Deductions' ? (parseFloat(item.deduction_pagibig)) > 0 :
                        (dec_ty === 'Accounting Loan Deductions' ? (parseFloat(item.deduction_loan)) > 0 :
                          (dec_ty === 'SSS Loan Deductions' ? (parseFloat(item.deduction_sss_loan)) > 0 :
                            (dec_ty === 'Pag-ibig Loan Deductions' ? (parseFloat(item.deduction_pagibig_loan)) > 0 :
                              (dec_ty === 'Other Receivables Deductions' ? (parseFloat(item.deduction_orec)) > 0 :
                                (dec_ty === 'Cash Bond Deductions' ? (parseFloat(item.deduction_cash_bond)) > 0 : false))))))))) {
                payments_array.push([
                  {
                    text: index + 1,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.branch,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.position,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.status,
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                  {
                    text: (dec_ty === 'Tax Deductions' ? item.deduction_tax :
                      (dec_ty === 'SSS Deductions' ? item.deduction_sss :
                        (dec_ty === 'PhilHealth Deductions' ? item.deduction_philhealth :
                          (dec_ty === 'Pag-ibig Deductions' ? item.deduction_pagibig :
                            (dec_ty === 'Accounting Loan Deductions' ? item.deduction_loan :
                              (dec_ty === 'SSS Loan Deductions' ? item.deduction_sss_loan :
                                (dec_ty === 'Pag-ibig Loan Deductions' ? item.deduction_pagibig_loan :
                                  (dec_ty === 'Other Receivables Deductions' ? item.deduction_orec :
                                    (dec_ty === 'Cash Bond Deductions' ? item.deduction_cash_bond : 0))))))))) / 1
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                ])
              }

              if (Object.is(payment.length - 1, index)) {
                // execute last item logic
                payments_array.push([
                  {
                    text: '',
                  }, {
                    text: '',
                  },
                  {
                    text: 'TOTAL',
                    alignment: 'right',
                    style: 'tableExample2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },

                  {
                    text: (total / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                ])
              }
              break;
            case "Auto Loan Deductions":
              total += parseFloat(item.deduction_auto_loan)
              if ((parseFloat(item.deduction_auto_loan)) > 0) {
                payments_array.push([
                  {
                    text: index + 1,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.branch,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.position,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.status,
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                  {
                    text: (item.deduction_auto_loan / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                ])
              }

              if (Object.is(payment.length - 1, index)) {
                // execute last item logic
                payments_array.push([
                  {
                    text: '',
                  }, {
                    text: '',
                  },
                  {
                    text: 'TOTAL',
                    alignment: 'right',
                    style: 'tableExample2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },

                  {
                    text: (total / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                ])
              }
              break;
            case "Cash Advance Deductions":
              total += parseFloat(item.deduction_ca)
              if ((parseFloat(item.deduction_ca)) > 0) {
                payments_array.push([
                  {
                    text: index + 1,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.branch,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.position,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.status,
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                  {
                    text: (item.deduction_ca / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                ])
              }

              if (Object.is(payment.length - 1, index)) {
                // execute last item logic
                payments_array.push([
                  {
                    text: '',
                  }, {
                    text: '',
                  },
                  {
                    text: 'TOTAL',
                    alignment: 'right',
                    style: 'tableExample2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },

                  {
                    text: (total / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                ])
              }
              break;
            case "Others Deductions":
              total += parseFloat(item.deduction_others)
              if ((parseFloat(item.deduction_others)) > 0) {
                payments_array.push([
                  {
                    text: index + 1,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.branch,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.position,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.status,
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                  {
                    text: (item.deduction_others / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                ])
              }

              if (Object.is(payment.length - 1, index)) {
                // execute last item logic
                payments_array.push([
                  {
                    text: '',
                  }, {
                    text: '',
                  },
                  {
                    text: 'TOTAL',
                    alignment: 'right',
                    style: 'tableExample2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },

                  {
                    text: (total / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                ])
              }
              break;
            case "Motor Finance Deductions":
              total += parseFloat(item.deduction_motor)
              if ((parseFloat(item.deduction_motor)) > 0) {
                payments_array.push([
                  {
                    text: index + 1,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.branch,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.position,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.status,
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                  {
                    text: (item.deduction_motor / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                ])
              }

              if (Object.is(payment.length - 1, index)) {
                // execute last item logic
                payments_array.push([
                  {
                    text: '',
                  }, {
                    text: '',
                  },
                  {
                    text: 'TOTAL',
                    alignment: 'right',
                    style: 'tableExample2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },

                  {
                    text: (total / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                ])
              }
              break;
            case "Plan Deductions":
              total += parseFloat(item.deduction_plan)
              if ((parseFloat(item.deduction_plan)) > 0) {
                payments_array.push([
                  {
                    text: index + 1,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.branch,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.position,
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                  {
                    text: item.status,
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                  {
                    text: (item.deduction_plan / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },
                ])
              }

              if (Object.is(payment.length - 1, index)) {
                // execute last item logic
                payments_array.push([
                  {
                    text: '',
                  }, {
                    text: '',
                  },
                  {
                    text: 'TOTAL',
                    alignment: 'right',
                    style: 'tableExample2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },

                  {
                    text: (total / 1)
                      .toFixed(2)
                      .replace(',', '.')
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                    alignment: 'left',
                    style: 'tableExample2',
                  },

                ])
              }
              break;
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[
                      this.month_of_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.month_of_id)
                      ].month_of,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'DURATION: ',
                  {
                    text: this.duration,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'CATEGORY: ',
                  {
                    text: this.category_items[
                      this.category_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.category_id)
                      ].category,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'BATCH #: ',
                  {
                    text: this.batch_no,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'POSITION: ',
                  {
                    text: this.position_items[
                      this.position_items
                        .map(function (x) {
                          return x.position
                        })
                        .indexOf(this.positionss)
                      ].position,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'BANK TYPE: ',
                  {
                    text: this.bank_type_items[
                      this.bank_type_items
                        .map(function (x) {
                          return x.bank_type
                        })
                        .indexOf(this.bank_type)
                      ].bank_type,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LEGAL',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image:
                    this.position === 'ADMIN'
                      ? this.admin
                      : this.position === 'TREASURY'
                      ? this.trea
                      : this.position === 'ACCOUNTING'
                        ? this.accounting
                        : this.position === 'AMD'
                          ? this.amd
                          : this.position === 'PROPERTY CUSTODIAN'
                            ? this.prot
                            : this.position === 'HUMAN RESOURCE'
                              ? this.hr
                              : this.position === 'BOOKKEEPING'
                                ? this.book
                                : this.position === 'CMD'
                                  ? this.accounting
                                  : imgData,
                  width: 60,
                  height: 54,
                  style: 'logo',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'PAYROLL REPORT',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printingss_, width: 34, height: 20, style: 'logo'},
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printingss = false
      },
      async print_data3() {
        this.printingss = true
        var imgData = this.company_logo

        var payments_array = []
        var widths = []
        var info_array = []
        var cheque_array = {}

        var damayan_ = this.damayan
        var chapel_ = this.chapel
        var factory_ = this.factory
        var coop_ = this.coop
        var printingss_ = this.printing

        widths = [120,80, 60, 120, 120]
        payments_array.push([
          {text: 'Branch', alignment: 'left', style: 'main_info'},
          {text: 'Account #', alignment: 'left', style: 'main_info'},
          {text: 'Net Salary', alignment: 'left', style: 'main_info'},
          {text: 'Name', alignment: 'left', style: 'main_info'},
          {text: 'Credit To', alignment: 'left', style: 'main_info'},
        ])

        var net_sal = 0
        this.payroll_data.forEach(function (item, index, payment) {
          net_sal += parseFloat(item.net_salary)
          payments_array.push([
            {
              text: item.employee.branch.branch_code,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.bank_account_no.replaceAll('-', ''),
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.net_salary,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.employee.last_name + ',' + item.employee.first_name + ' ' + item.employee.middle_name,
              alignment: 'left',
              style: 'tableExample2',
            },
            {
              text: item.bank_account_name,
              alignment: 'left',
              style: 'tableExample2',
            },
          ])
          if (Object.is(payment.length - 1, index)) {
            // execute last item logic
            payments_array.push([
              {
                text: '',
              },
              {
                text: 'TOTAL',
                alignment: 'right',
                style: 'tableExample2',
              },
              {
                text: (net_sal / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2',
              },
              {
                text: '',
              },
              {
                text: '',
              },
            ])
          }
        })

        info_array = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'MONTH OF: ',
                  {
                    text: this.month_of_items[
                      this.month_of_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.month_of_id)
                      ].month_of,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'DURATION: ',
                  {
                    text: this.duration,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'CATEGORY: ',
                  {
                    text: this.category_items[
                      this.category_items
                        .map(function (x) {
                          return x.id
                        })
                        .indexOf(this.category_id)
                      ].category,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'BATCH #: ',
                  {
                    text: this.batch_no,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'POSITION: ',
                  {
                    text: this.position_items[
                      this.position_items
                        .map(function (x) {
                          return x.position
                        })
                        .indexOf(this.positionss)
                      ].position,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'BANK TYPE: ',
                  {
                    text: this.bank_type_items[
                      this.bank_type_items
                        .map(function (x) {
                          return x.bank_type
                        })
                        .indexOf(this.bank_type)
                      ].bank_type,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
        ]

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: 'LEGAL',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 60, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader2'},
                    {
                      text: this.contact,
                      style: 'subheader2',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
                {
                  image:
                    this.position === 'ADMIN'
                      ? this.admin
                      : this.position === 'TREASURY'
                      ? this.trea
                      : this.position === 'ACCOUNTING'
                        ? this.accounting
                        : this.position === 'AMD'
                          ? this.amd
                          : this.position === 'PROPERTY CUSTODIAN'
                            ? this.prot
                            : this.position === 'HUMAN RESOURCE'
                              ? this.hr
                              : this.position === 'BOOKKEEPING'
                                ? this.book
                                : this.position === 'CMD'
                                  ? this.accounting
                                  : imgData,
                  width: 60,
                  height: 54,
                  style: 'logo',
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'PAYROLL REPORT',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: info_array,
            },
            {
              columns: [{text: ' '}],
            },
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
              layout: {
                fillColor: function (rowIndex) {
                  return rowIndex === 0 ? '#CCCCCC' : null
                },
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.position,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'AUDITED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (TREASURY)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'APPROVED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'Signature Over Printed Name (ACCOUNTING)',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'ALBERT M. HILARIO',
                      style: 'main_data_2',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'EVP HEAD FINANCE',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name (EXECUTIVE)',
                      style: 'main_data_2',
                    },
                  ],
                },
              ],
            },

            cheque_array,
          ],
          footer: function (currentPage, pageCount) {
            return {
              margin: 10,
              columns: [
                {
                  text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                  fontSize: 9,
                  alignment: 'left',
                },
                {image: damayan_, width: 34, height: 20, style: 'logo'},
                {image: chapel_, width: 34, height: 20, style: 'logo'},
                {image: factory_, width: 34, height: 20, style: 'logo'},
                {image: coop_, width: 34, height: 20, style: 'logo'},
                {image: printingss_, width: 34, height: 20, style: 'logo'},
                {
                  text: '',
                },
              ],
            }
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            subheader2: {
              fontSize: 10,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 9,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.printingss = false
      }
    },
  }
</script>
